import React, { Component, Fragment } from "react";
import '../images.css';

class About extends Component {


    render() {
        return (
            <Fragment>
                <h1 className="title is-size-3 has-text-centered shadowed">
              About
                    </h1>

            </Fragment>
        )
    };
}
export default About;